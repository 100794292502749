export enum UIACTION {
	DOWNLOAD_LEAD_HISTORY = "/UI/ACTION/download-lead-history",
	SEND_CONTRACT_DIGITEL = "/UI/ACTION/send-contract-digitel",
	CUSTOMER_PRODUCT_MODIFICATION_ACTION = "/UI/ACTION/customer-product-modification-action",
	CUSTOMER_PRODUCT_TRANSITION_ACTION = "/UI/ACTION/customer-product-transition-action",
	CUSTOMER_PRODUCT_TRANSITION_ONLY_MOBILES_ACTION = "/UI/ACTION/customer-product-transition-only-mobiles-action",
	CUSTOMER_PRODUCT_TRANSITION_SHARED_MOBILES_ACTION = "/UI/ACTION/customer-product-transition-shared-mobiles-action",
	CUSTOMER_PRODUCT_TERMINATE_ACTION = "/UI/ACTION/customer-product-terminate-action"
}

export enum Role {
	ADMIN = "admin",
	MANAGER = "manager",
	OPERATOR = "operator"
}

export enum StorageConfiguration {
	B2B_SEGMENTS = "B2B_SEGMENTS",
	BILLING_CYCLES = "BILLING_CYCLES",
	BLACKLISTED_APARTMENTS_CHANNELS = "BLACKLISTED_APARTMENTS_CHANNELS",
	BLACKLISTED_APARTMENTS_OPERATORS = "BLACKLISTED_APARTMENTS_OPERATORS",
	CONTRACT_SPECIFIC_NETWORKS = "CONTRACT_SPECIFIC_NETWORKS",
	CUSTOMER_PRODUCT_ACTION_OMIT_DEBT_VALIDATION_CHANNELS = "CUSTOMER_PRODUCT_ACTION_OMIT_DEBT_VALIDATION_CHANNELS",
	DEPENDENCY_GROUPS = "DEPENDENCY_GROUPS",
	DIGITEL_CONTRACT_CHANNELS_B2B = "DIGITEL_CONTRACT_CHANNELS_B2B",
	DIGITEL_CONTRACT_CHANNELS_B2C = "DIGITEL_CONTRACT_CHANNELS_B2C",
	FORBIDDEN_SALE_PROCESS_STATUSES = "FORBIDDEN_SALE_PROCESS_STATUSES",
	IBAN_SUPPORTED_COUNTRIES = "IBAN_SUPPORTED_COUNTRIES",
	PRODUCTS_MANDATORY_DOCUMENTS = "PRODUCTS_MANDATORY_DOCUMENTS",
	PROMOTION_GROUPS = "PROMOTION_GROUPS",
	PROVINCE_PREFIXES = "PROVINCE_PREFIXES",
	REFERENCE_DATES = "REFERENCE_DATES",
	RETAIL_EXCLUSIVITY_RULES = "RETAIL_EXCLUSIVITY_RULES",
	SALES_AREAS = "SALES_AREAS",
	SEND_BSS_SERVICIABLE_STATUSES = "SEND_BSS_SERVICIABLE_STATUSES",
	SMS_CONTRACT_CHANNELS = "SMS_CONTRACT_CHANNELS",
	SUPER_MANAGERS = "SUPER_MANAGERS"
}

export enum DropdownTypes {
	ADDRESS_STATUS_TYPES = "ADDRESS_STATUS_TYPES",
	BILL_STATE_TYPES = "BILL_STATE_TYPES",
	BILL_TYPE_TYPES = "BILL_TYPE_TYPES",
	BUSINESS_COMPANY_IDENTIFICATION_TYPES = "BUSINESS_COMPANY_IDENTIFICATION_TYPES",
	BUSINESS_INDIVIDUAL_IDENTIFICATION_TYPES = "BUSINESS_INDIVIDUAL_IDENTIFICATION_TYPES",
	BUSINESS_TYPES = "BUSINESS_TYPES",
	CHARACTERISTIC_GROUP_TYPES = "CHARACTERISTIC_GROUP_TYPES",
	CONTACT_MEDIUM_TYPES = "CONTACT_MEDIUM_TYPES",
	CUSTOMER_ACCOUNT_DOCUMENT_TYPES = "CUSTOMER_ACCOUNT_DOCUMENT_TYPES",
	CUSTOMER_ASSOCIATION = "CUSTOMER_ASSOCIATION",
	CUSTOMER_STATE_TYPES = "CUSTOMER_STATE_TYPES",
	LANGUAGES = "LANGUAGES",
	OPERATOR_PREFERRED_CONTACT_TIME_TYPES = "OPERATOR_PREFERRED_CONTACT_TIME_TYPES",
	OPERATOR_TITLE_TYPES = "OPERATOR_TITLE_TYPES",
	ORDER_STATE_TYPES = "ORDER_STATE_TYPES",
	PRODUCT_ACTION_STATUS_TYPES = "PRODUCT_ACTION_STATUS_TYPES",
	PRODUCT_CHARACTERISTIC_ACTION_TYPES = "PRODUCT_CHARACTERISTIC_ACTION_TYPES",
	PRODUCT_TERMINATION_REASON_TYPES = "PRODUCT_TERMINATION_REASON_TYPES",
	RELATED_PARTY_TYPES = "RELATED_PARTY_TYPES",
	RESIDENTIAL_IDENTIFICATION_TYPES = "RESIDENTIAL_IDENTIFICATION_TYPES",
	RETAIL_EXCLUSIVITY_RULES_MONTHS = "RETAIL_EXCLUSIVITY_RULES_MONTHS",
	ROLES = "ROLES",
	SALES_PERSON_TYPES = "SALES_PERSON_TYPES",
	TASK_CATEGORY_TYPES = "TASK_CATEGORY_TYPES",
	TASK_STATE_TYPES = "TASK_STATE_TYPES",
	TENANTS = "TENANTS",
	TRANSITION_DOWNGRADE_REASON_TYPES = "TRANSITION_DOWNGRADE_REASON_TYPES",
	DIGITEL_CIRCUIT_TYPES = "DIGITEL_CIRCUIT_TYPES"
}

export enum ProductInstanceAction {
	ADD = "ADD",
	NO_CHANGE = "NO_CHANGE",
	DELETE = "DELETE",
	SWAP = "SWAP",
	MAKE_PRINCIPAL = "MAKE_PRINCIPAL"
}
